@import '_breakpoints.scss';

.container {
  @include make-container();
  @include make-container-max-widths();
}

.container-fluid {
  @include make-container();
}

// IMPORTANT: I've added a 'wow' prefix before row and col classes so It'd not
// clash with the old bootstrap. We may revert that in the future.
// Also It's been also modified under mixins/_grid-framework.scss

// Row
//
// Rows contain and clear the floats of your columns.

.wow-row {
  @include make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .wow-col,
  > [class*='wow-col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

// wider gutters for food-hub
.wide-gutters {
  margin-right: -16px;
  margin-left: -16px;

  > .wow-col,
  > [class*='wow-col-'] {
    padding-right: 16px;
    padding-left: 16px;
  }

  @media (min-width: $screen-md-min) {
    margin-right: -24px;
    margin-left: -24px;

    > .wow-col,
    > [class*='wow-col-'] {
      padding-right: 24px;
      padding-left: 24px;
    }
  }
}

// Columns
//
// Common styles for small and large grid columns
@include make-grid-columns();
