@use 'sass:math';
@import 'colors';
@import 'zindex';

html {
  &.smooth-scroll {
    scroll-behavior: smooth;
  }
}

body {
  background: $color-softGrey;
  color: $color-charcoal;
  font-family: $fontFamily-body;
  font-size: $fontSize-base;
  line-height: math.div(26, 16);
  text-size-adjust: none;

  .search-backdrop {
    background-color: $color-charcoal--darkest;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    display: block;
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &.search-active {
    .shop-content::before {
      z-index: $zIndex-4--coreHeaderSearchBackdropNotAdaptive;
      @extend .search-backdrop;
    }
  }

  &.search-active-adaptive {
    ar-partial::before {
      z-index: $zIndex-4--coreHeaderSearchBackdrop;
      @extend .search-backdrop;
    }
  }
}

.body-container {
  overflow: hidden; // has to be on a child of body for iOS

  &.body-container-overflow {
    overflow: visible; // for adaptive product healthy swap
  }
}
