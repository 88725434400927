@import 'colors';
@import 'typography';

// block style elements which should be part
// of the vertical rhythm
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dd,
p,
blockquote,
figure,
pre,
table,
fieldset,
hr,
audio,
video {
  margin-bottom: 20px;
}

abbr[title],
acronym[title] {
  border-bottom: none;
  text-decoration: none;
}

sup {
  position: initial;
}

.u-error {
  color: $color-red--error;
  font-weight: bold;
}
