@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Regular_400.woff2')
      format('woff2'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Regular_400.woff')
      format('woff'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Regular_400.ttf')
      format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Medium_500.woff2')
      format('woff2'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Medium_500.woff')
      format('woff'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Medium_500.ttf')
      format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Bold_700.woff2')
      format('woff2'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Bold_700.woff')
      format('woff'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/roboto/Roboto-Bold_700.ttf')
      format('truetype');
}
