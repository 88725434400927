.pac {
  &-container {
    background-color: $color-grayLighterest;
    border: 1px solid $color-grayLight;
    border-top: none;
    border-radius: 0;
    left: 1px !important;
    box-shadow: 0 4px 4px -2px $color-grayDark;
  }

  &-item {
    font-size: $fontSize-base;
    height: $height-input;
    line-height: $height-input;
    border: none;

    &-query {
      font-size: inherit;
    }

    &:hover,
    &-selected {
      background: $color-grayLight;
    }
  }

  &-icon {
    margin-top: 13px;
  }

  &-logo:after {
    display: none;
  }
}
