@import 'variables';
@import '_colors';
@import 'typography';
@import 'mixins/mixins';

.wowRadio {
  $size-radio: 24px;

  @include sr-only();

  + label {
    position: relative;
    display: inline-block;
    padding-left: 40px;
    cursor: pointer;
    font-family: $fontFamily-headline;
    font-weight: var(--font-weight-medium);

    &:after {
      content: '';
      display: inline-block;
      @include vertical-align();
      left: 0;

      width: $size-radio;
      height: $size-radio;

      border: 2px solid $color-midGreen;
      border-radius: 100%;
    }
  }

  &:focus + label {
    &:after {
      outline: 1px gray dotted;
    }
  }

  &:hover + label {
    &:after {
      border-color: $color-charcoal--lighter;
      background-color: $color-charcoal--lighter;
    }
  }

  &:checked + label {
    font-weight: $fontWeight-bold;
    color: $color-midGreen;
    &:after {
      border-color: $color-midGreen;
      border-width: 8px;
      background-color: $color-white;
    }
  }

  &:disabled + label {
    opacity: 0.5;
    pointer-events: none;
  }
}

.wowRadio.wowRadio--top {
  + label:after {
    top: 20px;
  }
}
