@import 'variables';
@import 'mixins/mixins';
@import '_colors';

.wowCheckbox {
  $size-checkbox: 24px;

  @include sr-only();

  + label {
    position: relative;
    display: inline-block;
    padding-left: 42px;
    cursor: pointer;
    font-family: $fontFamily-headline;
    font-weight: var(--font-weight-medium);
    color: $color-midGreen;

    &::after {
      content: '';
      display: inline-block;
      width: $size-checkbox;
      height: $size-checkbox;
      @include vertical-align();

      left: $space-xs;
      border: 2px solid $color-midGreen;
      background-size: 50%;
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }

  &:focus + label {
    &::after {
      outline: 1px $color-gray dotted;
    }
  }

  &:hover + label {
    &::after {
      border-color: $color-charcoal--lightest;
      background-color: $color-charcoal--lightest;
    }
  }

  &:checked + label {
    color: $color-charcoal;

    &::after {
      border-color: $color-midGreen;
      background-color: $color-midGreen;
      background-image: $image-tickIcon;
      background-size: 100%;
    }
  }

  &:disabled + label {
    pointer-events: none;
    opacity: 0.5;
  }

  &.label-before {
    + label {
      padding-right: $space-xl;
      padding-left: 0;
      &::after {
        width: 16px;
        height: 16px;
        right: $space-xs;
        left: unset;
      }
    }
  }
}
