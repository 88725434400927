﻿//== Widths, heights, paddings, and margins
//
//## Reusable values for spacings (for consistency).

// Paddings
// -------------------------------------------------
// This value overrides the bootstrap default space between columns in the grid.
$grid-gutter-width: 10px;
$card-gutter-width: 10px;
$tile-gutter-width: 20px;
$tile-gutter-width-xs: ($tile-gutter-width * 0.5);
$size-one: 1px;

$padding-xs: 3px;
$padding-s: 15px;

// Margins
// -------------------------------------------------
$margin-col: 15px;
$margin-pagePanel: 60px;
$margin-loginPanel: $margin-pagePanel;
$margin-row: 64px;

// Widths
// -------------------------------------------------
$width-leftPanel: 174px;
$width-rightPanel: 174px;
$width-tileColumn: 240px;
$width-tile: 220px;
$width-list: 800px;

$width-leftPanelVisualShopping: 320px;
$width-refreshNavigation: 220px;

$width-list-tobacco-banner-lg: 728px;
$width-list-tobacco-banner-sm: 320px;
// This navigation width determines the width of the left panel.
$width-navigation: $width-refreshNavigation;

$width-collapsedCart: 80px;
$width-expandedCart: 340px;
$width-expandedDrawer: 470px;

$width-button: 44px;

$width-col: 174px;
$width-onesiteCol: 300px;
$width-onesiteColOuterWidth: 310px;

$width-staticContent: 550px;

$width-shelfProductStamp: 310px;
$width-shelfProductStamp-compact: 210px;

$width-openclosePanelButtons: 44px;

$width-shelfproduct-detail-card: 270px;

$width-card: 300px;
$width-card-compact: 200px;

// Heights
// -------------------------------------------------
$height-toolbar: 44px; // height of global header
$height-toolbar-full: 120px; // height of the global header when expended
$height-toolbar-top: 76px; // height of the global header top panel

$height-checkoutFooter: 54px;

$height-input: 44px;

$height-button: 44px;
$height-menuItem: 44px;
$height-menuHeader: 44px;

$height-shelfproduct-detail-card: 450px;

$height-shelfproductstamp-header: 50px;

$height-tile: 480px;
$height-list: 300px;
$height-card: $height-tile; // height of the ui refresh tiles

// Header heights from UI refresh
$height-corporateHeader: 30px;
$height-coreHeader: 70px;
$height-coreHeaderXs: 60px;
$height-categoryHeader: 50px;
$height-categoryHeaderSm: 50px;
$height-categoryPanelHeader: 110px;

$height-header: $height-corporateHeader + $height-coreHeader +
  $height-categoryHeader; // height of the ui refresh header
$height-headerSf: $height-coreHeaderXs + $height-categoryHeaderSm; // height of the ui refresh header in sf

// Other
// -------------------------------------------------

// New My account design related variables
$width-card-button: 180px;
