@import 'variables';
@import '@woolworthslimited/core-styles-components-button/dist/mixins/button.scss';

button.secondary {
  @include core-button-variant('secondary');
}

button.tertiary {
  @include core-button-variant('tertiary');
}

button.destructive {
  @include core-button-variant('destructive');
}

.button {
  @include core-button;
  cursor: pointer;
  align-items: center;

  &.sm {
    @include core-button-small;
    height: 32px;
    min-width: 64px;
    padding-left: $space-md;
    padding-right: $space-md;
  }

  &.m {
    height: 40px;
    min-width: 104px;
    padding-left: $space-lg;
    padding-right: $space-lg;
  }

  &.l {
    height: 48px;
    min-width: 112px;
    padding-left: $space-lg;
    padding-right: $space-lg;
  }

  &.xl {
    height: 56px;
    min-width: 128px;
    padding-left: $space-xl;
    padding-right: $space-xl;
  }

  &.mobile-full-width {
    @media screen and (max-width: $breakpoint-mobile-max) {
      width: 100%;
    }
  }

  &.full-width {
    @include core-button-full-width;
  }

  &.primary {
    @extend .primary;
  }

  &--primary {
    @extend .primary;
  }

  &--primaryDark {
    @extend .primary;
  }

  &--reversed-primary {
    @extend .primary;
  }

  &.reversed-primary {
    @extend .primary;
  }

  &.reversed-secondary {
    @include core-button-variant('secondary');
  }

  &--special {
    background: $color-yellow;
    border-color: $color-yellow;
    color: $color-charcoal;

    &:hover,
    &:focus {
      background-color: $color-yellow--darker;
      border-color: $color-yellow--darker;
      color: $color-charcoal;
    }

    &:active {
      background: $color-yellow--darkest;
      border-color: $color-yellow--darkest;
      color: $color-charcoal;
    }

    &:disabled,
    &.is-disabled {
      background-color: $color-yellow--lightest;
      border-color: $color-yellow--lightest;
      color: $color-charcoal--lightest;
    }
  }

  &.secondary {
    @include core-button-variant('secondary');
  }

  &--secondary {
    @include core-button-variant('secondary');
  }

  &--secondaryDark {
    @include core-button-variant('secondary');
  }

  &--secondaryLight {
    @include core-button-variant('secondary');
  }

  &.secondary-filled {
    @include core-button-variant('secondary');
  }

  &.destructive {
    @include core-button-variant('destructive');
  }

  &--danger {
    @include core-button-variant('destructive');
  }

  &--comms {
    background-color: $color-grayLightest;
    border-color: $color-grayLightest;
    color: $color-grayDarker;
    cursor: default;

    &:hover,
    &:focus,
    &:active {
      color: $color-grayDarker;
      box-shadow: none;
      border-color: $color-grayLightest;
    }

    &:disabled,
    &.is-disabled {
      opacity: 1;
      border-color: $color-grayLightest;
      color: $color-grayDarker;
    }
  }

  // Small version of the button to be used inside inputs
  &--small {
    @include core-button-small;
  }

  &--medium {
    height: 40px;
    min-width: 104px;
    max-width: 344px;
    padding: 0 $space-lg;
  }

  &--large {
    height: 48px;
    line-height: 44px;
    min-width: 112px;
    max-width: 392px;
    padding: 0 $space-lg;
  }

  &--xLarge {
    height: 56px;
    min-width: 128px;
    max-width: 464px;
    padding: 0 $space-xl;
    font-size: $fontSize-base;
  }
}

a.button {
  &:disabled,
  &.is-disabled {
    pointer-events: none;
  }
}

.linkButton {
  display: inline;
  background: none;
  border: none;
  font-family: $fontFamily-headline;
  font-weight: var(--font-weight-medium);
  font-size: $fontSize-base;
  cursor: pointer;
  padding: 0;

  &:disabled,
  &.is-disabled {
    color: inherit;
    cursor: default;
    text-decoration: none;
  }
}

.iconButton {
  cursor: pointer;
  border: none;
  background: none;
  font-size: $fontSize-regularEm;
}

.oly-button {
  display: inline-block;

  &-loading {
    cursor: auto;
    display: inline-block;

    .spinner-circle {
      margin: 11px auto;
      width: 20px;
      height: 20px;

      .spinner-container > div {
        width: 4px;
        height: 4px;
        background-color: $color--brandPrimary;
      }
    }

    &.button--primary {
      .spinner-circle {
        .spinner-container > div {
          background-color: $color-white;
        }
      }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(0px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}
