﻿//== Z-index
//
//## Z-index values should always be defined in this file. This allows us to at a
//## glance determine relative layers of our application and prevents bugs
//## arising from arbitrary z-index values. Do not edit the z-index scale! Only
//## add application scoped z-index values.

// Public reset
$zIndex-reset: auto;

// Z-Index Scale (PRIVATE VARIABLES, DO NOT USE)
// --------------------------------------------------
$zIndex-1: 100; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-2: 200; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-3: 300; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-4: 400; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-5: 500; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-6: 600; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-7: 700; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-8: 800; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-9: 900; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-10: 1000; // DON'T USE ME OUTSIDE THIS FILE
$zIndex-11: 1100; // DON'T USE ME OUTSIDE THIS FILE

// Z-Index Applications
// --------------------------------------------------

// Layout
$zIndex-5--openClosePanel: $zIndex-5;
$zIndex-8--header: $zIndex-8;
$zIndex-7--header: $zIndex-7;
$zIndex-3--header: $zIndex-3;
$zIndex-2--headerMask: $zIndex-2;
$zIndex-3--leftPanel: $zIndex-3;
$zIndex-5--rightPanel: $zIndex-5;
$zIndex-9--rightPanel: $zIndex-9; // For Progressive side cart
$zIndex-4--menuPanel: $zIndex-4;
$zIndex-5--shopMask: $zIndex-5;
$zIndex-9--shopMask: $zIndex-9; // For Progressive side cart
$zIndex-2--cartMask: $zIndex-2;

// Common Components
$zIndex-1--dropdowns: $zIndex-1;
$zIndex-1--tooltip: $zIndex-1;
$zIndex-1--stickyTabs: $zIndex-1;
$zIndex-3--autocomplete: $zIndex-3;
$zIndex-2--confirmationDialog: $zIndex-2;
$zIndex-1--confirmationDialogMask: $zIndex-1;
$zIndex-8--loadingMask: $zIndex-8;
$zIndex-10--loadingSpinner: $zIndex-10;
$zIndex-4--sortBy: $zIndex-4;
$zIndex-6--wowscrollbar: $zIndex-6;
$zIndex--popup-backDrop: $zIndex-10;
$zIndex--popup: $zIndex-11;

// Order Management
$zIndex-2--orderManagementPanel: $zIndex-2;

// Specials
$zIndex-4--specialsDropdown: $zIndex-4;

// Delivery
$zIndex-2--deliveryTimetableCancel: $zIndex-2;

// Header
$zIndex-8--skipLinks: $zIndex-8;
$zIndex-5--coreHeaderSearch: $zIndex-5;
$zIndex-4--coreHeaderSearchBackdropNotAdaptive: $zIndex-5;
$zIndex-4--coreHeaderSearchBackdrop: $zIndex-8;
$zIndex-4--fulfilmentHeaderRibbon: $zIndex-4;
$zIndex-4--headerNotification: $zIndex-4;

// Lists
$zIndex-10--pastShopsListItem: $zIndex-10;
$zIndex-3--savedListsFixedHeader: $zIndex-3;
$zIndex-1--suggestedListFixedImage: $zIndex-1;
$zIndex-4--suggestedListSpinnerPointer: $zIndex-4;
$zIndex-4--suggestedListSpinnerSelected: $zIndex-4;
$zIndex-6--saveToListMenu: $zIndex-6;
$zIndex-7--saveToListMenu: $zIndex-7;

// My Account
$zIndex-1--myAccountFixedNavigation: $zIndex-1;

// Card detail
$zIndex-4--cardDetailWrapper: $zIndex-2;
$zIndex-1--cardDetailCloseButton: $zIndex-1;
$zIndex-4--cardDetailPointer: $zIndex-4;

// Card list
$zIndex-4--cardListSortByDropdown: $zIndex-4;

// Store Locator
$zIndex-1--storelocator-details: $zIndex-1;
$zIndex-1--storelocator-search: $zIndex-1;

// Search
$zIndex-4--categoryHeader: $zIndex-4;

// Partner
$zIndex-1--partner: $zIndex-1;

// Recipe
$zIndex-2--keepRecipe: $zIndex-2;
$zIndex-8--mealplanContent: $zIndex-8;

// Product
$zIndex-1--productTag: $zIndex-1;
$zIndex-reset--zoomContainer: $zIndex-reset;

// Checkout
$zIndex-1--checkoutHeader: $zIndex-1;
$zIndex-2--checkoutFooter: $zIndex-5; // This should be higher than Digital-Pay to ensure it's on top
$zIndex-3--checkoutPayment: $zIndex-6;
$zIndex-1--checkoutSf: $zIndex-1;
$zIndex-1--timePickerFade: $zIndex-1;

// Trolley Controls
$zIndex-1--trolleyControls: $zIndex-1;

// DigitalPay
$zIndex-1-digitalPayNormal: $zIndex-1;
$zIndex-2-digitalPaySelected: $zIndex-2;
$zIndex-2-digitalPayError: $zIndex-2;
$zIndex-2-digitalPayWarning: $zIndex-2;

//Shelf Product Stamp Pointer
$zIndex-2--productStampPointer: $zIndex-2;

$zIndex-1--viewMoreToggle: $zIndex-1;

// Filter
$zIndex-7--filterOverlay: $zIndex-7;
$zIndex-9--exposedFilterDropdownMask: $zIndex-9;
$zIndex-10--exposedFilterDropdown: $zIndex-10;

// Filter chips
$zIndex-1-filter-chips: $zIndex-1;

// Dialog component
$zIndex-10--dialogWrapper: $zIndex-10;
$zIndex-3--dialogContent: $zIndex-3;
$zIndex-2--dialogMask: $zIndex-2;

// modal component
$zIndex-10--modalWrapper: $zIndex-10;
$zIndex-8--modalMask: $zIndex-8;
$zIndex-9--modalContent: $zIndex-9;
$zIndex-1--modalCloseButton: $zIndex-1;

// search filter components
$zIndex-11--filterContent: $zIndex-11;
$zIndex-10--filterMask: $zIndex-10;

// Inline dropdown
$zIndex-4--dropdownItemsOpened: $zIndex-4;

// Tobacco Banner
$default-tobacco-zIndex: 850;
