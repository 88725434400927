@import 'breakpoints';
@import 'colors';

// HELVETICA
$fontFamily-helvetica: Helvetica, Arial, sans-serif;

// MONOSPACE - Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace !default;

// FONT
$fontFamily-body: var(--font-family-roboto); // ROBOTO
$fontFamily-headline: var(--font-family-fresh-sans); // FRESH SANS

//  Copied from the existing site
// Font sizes
// --------------------------------------------------------------------
$fontSize-base: 16px;

$fontSize-xxsmall: 8px;
$fontSize-xsmall: 10px;
$fontSize-small: 12px;
$fontSize-smallregular: 15px;
$fontSize-regular: $fontSize-base;
$fontSize-regularEm: 17px;
$fontSize-regularV2: 18px;
$fontSize-large: 20px;
$fontSize-xlarge: 24px;
$fontSize-xxlarge: 28px;
$fontSize-xxlargeV1: 32px;
$fontSize-xxlargeV2: 38px;
$fontSize-xxxlarge: 40px;

// Font sizes compact
//----------------------------------------------------------------------
$fontSize-regular--compact: $fontSize-small;
$fontSize-smallRegular--compact: 14px;

// Application font sizes
// eg. fontSize-regular--productStamp
$fontSize-smallish--rewards-points-tile-title: 13px;
$fontSize-smallish--loginForm-forgot-password: 13px;
$fontSize-smallish--productFilterCoachMark: 13px;
$fontSize-smallregular--inputError: 14px;
$fontSize-smallregular--inputSuccess: 14px;
$fontSize-xlarge--signupForm-heading: 23px;
$fontSize-xxlarge--orderstatuscard-time: 34px;
$fontSize-xlarge--recipeTileAmount: 22px;
$fontSize-xlarge--recipeBanner: 56px;
$fontSize-xxlarge--searchExternalResults: 30px;
$fontSize-xxxlarge--footerIcon: 32px;
$fontSize-xxxlarge--icon: 32px;
$fontSize-xsmall--headerBadge: 11px;
$fontSize-xsmall--selectedFilterDisplayCloseIcon: 11px;
$fontSize-xsmall--icon: 11px;
$fontSize-xlarge--expressEstimatedDeliveryTime: 22px;
$fontSize-xlarge--expressAvailableMessage: 22px;
$fontSize-xxlarge--2faTitle: 28px;
$fontSize-xxxlarge--MyAccountHeader: 32px;
$fontSize-xxxlarge--DeliverySaver: 60px;
$fontSize-xxxlarge--DeliveryCheckout: 48px;
$fontSize-xxlarge--DeliveryCheckout: 32px;
$fontSize-regular--DeliveryCheckout: 17px;

// Font weights
// --------------------------------------------------------------------
$fontWeight-ultraLight: 100; // Extra Light or Ultra Light
$fontWeight-light: 200; // Light or Thin
$fontWeight-semiLight: 300; // Book or Demi
$fontWeight-regular: var(--font-weight-regular); // 400 Normal or Regular
$fontWeight-medium: var(--font-weight-medium); // 500 Medium
$fontWeight-semiBold: var(--font-weight-semi-bold); // 600 Semibold, Demibold
$fontWeight-bold: var(--font-weight-bold); // 700 Bold
$fontWeight-extraBold: 800; // Black, Extra Bold or Heavy
$fontWeight-ultraBold: 900; // Extra Black, Fat, Poster or Ultra Black

// Application font weights
$fontWeigh-semiBold--deliveryWizardBold: $fontWeight-semiBold;

// Font style
// --------------------------------------------------------------------
$fontStyle-italic: italic;

// Line heights
// --------------------------------------------------------------------
$lineHeight-conversationalText: 1.7;

// Line heights (TEMP, DON'T USE)
// --------------------------------------------------------------------
//$lineHeight-tightest:       0.8;
//$lineHeight-tighter:        1;
//$lineHeight-tight:          1.1;
$lineHeight-base: 1.375;
//$lineHeight-loose:          1.5;
//$lineHeight-looser:         1.8;

// Letter spacing (TODO)
// --------------------------------------------------------------------
//$letterSpacing-tightest
//$letterSpacing-tighter
//$letterSpacing-tight
//$letterSpacing-normal
//$letterSpacing-loose
//$letterSpacing-looser

$primary-font-size: 16px;

$font-size-browse-header: 22px;
$line-height-browse-header: 28px;
