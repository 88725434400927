@font-face {
  font-family: Fresh Sans;
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Regular_1_350_web.woff2')
      format('woff2'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Regular_1_350_web.woff')
      format('woff'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Regular_1_350_web.ttf')
      format('truetype');
}

@font-face {
  font-family: Fresh Sans;
  font-style: normal;
  font-weight: 500;
  src: url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Medium_1_350_web.woff2')
      format('woff2'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Medium_1_350_web.woff')
      format('woff'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Medium_1_350_web.ttf')
      format('truetype');
}

@font-face {
  font-family: Fresh Sans;
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Bold_1_350_web.woff2')
      format('woff2'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Bold_1_350_web.woff')
      format('woff'),
    url('https://cdn0.woolworths.media/wowssr/browser/assets/fonts/fresh-sans/FreshSans-Bold_1_350_web.ttf')
      format('truetype');
}
