/* You can add global styles to this file, and also import other style files */
@import '@woolworthslimited/core-tokens/dist/woolworths/css/tokens.css';
@import '@woolworthslimited/core-styles-components-icon/dist/css/icon';
@import '@woolworthslimited/core-styles-typography/dist/css/woolworths';
@import 'styles/bootstrap/bootstrap';
@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins/mixins';
@import 'styles/utilities/utilities';
@import 'styles/grid';
@import 'styles/normalize';
@import 'styles/fonts/daveicons';
@import 'styles/fonts/freshsans';
@import 'styles/fonts/roboto';
@import 'styles/typography';
@import 'styles/typography-utilities';
@import 'styles/header';
@import 'styles/body';
@import 'styles/helperclasses';
@import 'styles/links';
@import 'styles/buttons';
@import 'styles/lists';
@import 'styles/headings';
@import 'styles/texts';
@import 'styles/flexbox';
@import 'styles/checkbox';
@import 'styles/validation';
@import 'styles/inputs';
@import 'styles/alerts';
@import 'styles/radios';
@import 'styles/headings';
@import 'styles/i11n';
@import 'styles/autocomplete';
@import 'styles/ghost-animation';
@import '@angular/cdk/overlay-prebuilt.css';
