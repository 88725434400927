.de-cursor-pointer {
  cursor: pointer;
}

.theme-background {
  background: white;
}

#de-container {
  width: 100%;
  position: relative;
  top: 0;
  padding: 5px 10px;
  z-index: 100000;
  font-size: 0.75rem;
}

#de-exceptions {
  display: none;
  margin-top: 5px;
  max-height: 200px;
  background: white;
  overflow-y: scroll;
  padding: 15px;
  border: 1px solid black;
}

#de-toggle-exceptions {
  text-decoration: underline;
}

#de-exceptions {
  display: block;
}

#de-hide-details {
  text-decoration: underline;
}
