@import 'variables';
@import 'colors';

a {
  color: $color-midGreen;
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: underline;
  }
}

a,
.link,
.linkButton {
  color: $color-midGreen;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: 1px dashed;
  }

  &:visited {
  }
  .icon-chevron_down,
  .icon-chevron_up,
  .iconww-ArrowDown,
  .iconww-ArrowUp,
  .iconww-ArrowRight,
  .iconww-ArrowLeft {
    font-size: 11px;
  }

  .accordion {
    &.icon-chevron_down,
    &.icon-chevron_up,
    &.iconww-ArrowDown,
    &.iconww-ArrowUp,
    &.iconww-ArrowRight,
    &.iconww-ArrowLeft {
      font-size: $fontSize-regular;
    }
  }
}

shared-notification {
  .notification-container {
    &.info {
      .small-heading {
        a {
          color: $color-blue;
        }
      }
    }
    &.warning {
      a {
        color: $color-orange--darkest;
      }
    }
    &.error {
      a {
        color: $color-red--darkest;
      }
    }
    &.success {
      a {
        color: $color-midGreen;
      }
    }
  }
}

shared-inline-notification-message {
  .message {
    &.info {
      a {
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }
}

.link-no-pointer-events > a * {
  pointer-events: none;
}

.link-underline {
  text-decoration: underline;

  a:hover,
  a:focus {
    color: $color-darkGreen--darker;
    cursor: pointer;
  }
}
