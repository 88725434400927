@import 'mixins/_focus';

:focus {
  @include focus;
}

.u-noOutline {
  &:focus,
  &:active {
    outline: none;
  }
}
