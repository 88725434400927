@import 'breakpoints';
@import 'colors';
@import 'typography';
@import 'spacing';

h1,
h2,
h3,
h4,
h5,
h6,
.heading1,
.heading2,
.heading3,
.heading4,
.heading5,
.heading6,
.heading {
  color: $color-charcoal;
  font-family: $fontFamily-headline;
  font-weight: var(--font-weight-medium);
}

label,
select,
option,
input {
  color: $color-charcoal;
  font-weight: normal;
}

h1,
.heading1 {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: 38px;
    line-height: 40px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: $fontSize-xxlarge;
    line-height: 32px;
  }
}

h2,
.heading2 {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: $fontSize-xxlarge;
    line-height: 32px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: $fontSize-xlarge;
    line-height: 28px;
  }
}

h3,
.heading3 {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: $fontSize-xlarge;
    line-height: 28px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: 20px;
    line-height: 24px;
  }
}

h4,
.heading4 {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: $fontSize-regular;
    line-height: 24px;
  }
}

h5,
.heading5 {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: $fontSize-regular;
    line-height: 24px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: 14px;
    line-height: 20px;
  }
}

h6,
.heading6 {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: 14px;
    line-height: 20px;
  }
}

p {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: $fontSize-regular;
    line-height: 24px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: 14px;
    line-height: 20px;
  }
}

/* Needs global as the html comes from dynamic content */
.payment-disclaimer p {
  font-size: $fontSize-small;
  line-height: 20px;
  margin: 0;
}

.small-heading {
  @media screen and (min-width: $breakpoint-desktop-min) {
    font-size: 14px;
  }

  @media (max-width: $breakpoint-tablet-max) {
    font-size: $fontSize-small;
  }
}

.smaller-heading {
  font-size: $fontSize-small;
}

// Additionally apply Darker color and Paddings
.heading3V2 {
  margin-bottom: $space-md;
  padding-top: $space-xl;
  @media screen and (min-width: $breakpoint-desktop-min) {
    padding-left: $space-6xl;
  }

  @media (max-width: $breakpoint-tablet-max) {
    padding-left: $space-md;
    padding-top: $space-lg;
  }
  @media only screen and (min-device-width: $screen-md-min) and (max-device-width: $screen-md-min) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    //padding-left: $space-md;
    padding-top: $space-xl;
  }
  @media only screen and (min-device-width: $screen-md-min) and (max-device-width: $screen-md-min) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    padding-left: $space-md;
  }
  position: static;
  color: $color-charcoal--darker;
}

.heading5V2 {
  color: $color-charcoal;
  max-width: $hundred-percent;
  margin-bottom: $space-0;
}
