@import 'variables';
@import 'mixins/mixins';
$input-container-padding: 10px;

$input-label-default-offset: -50%;
$input-label-default-scale: 1;
$input-label-float-offset: -18px;
$input-label-float-scale: 0.75;

$input-placeholder-offset: 19px;
$input-placeholder-float-offset: 15px;
$input-padding-top: $space-md;

$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
$input-label-transition-speed: 0.25s;

fieldset {
  margin: 0;
}

input[type='text'],
input[type='search'],
input[type='email'],
input[type='url'],
input[type='tel'],
input[type='password'],
input[type='number'],
input[type='datalist'],
textarea,
select {
  appearance: none;
  -moz-appearance: textfield;
  height: $height-input;
  font-size: $fontSize-regular;
  font-family: $fontFamily-body;
  padding: 0 10px;
  color: $color-charcoal;
  background: $color-grayLightest;
  border: 1px solid $color-charcoal--lightest;
  border-radius: 0;
  outline: none;

  &:focus,
  &:hover {
    border: 1px solid $color-charcoal;
  }

  &:focus {
    background-color: white;
  }

  &:disabled,
  &.is-disabled,
  &:disabled + label {
    opacity: 0.5;
  }
}

textarea {
  padding-top: 10px;
  resize: none;
}

.wowInputContainer,
wow-input-container {
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  width: 100%;

  .wowInput {
    display: block;
    padding-top: $input-padding-top;
    font-size: $fontSize-base;
    width: 100%;

    &--small {
      width: 45%;
    }

    &-errors {
      display: none;
    }

    &-error {
      color: $color-red--error;
      font-size: $fontSize-smallregular--inputError;
    }

    &-success {
      color: $color--brandPrimary;
      font-size: $fontSize-smallregular--inputSuccess;
    }
  }

  .wowInput-icon,
  .wowInput-control {
    width: 35px;
    height: $height-input;
    padding: 0;

    @include vertical-align();
    right: 0;

    background: none;
    border: none;

    font-size: $fontSize-base;
    color: $color-grayMed;
    cursor: pointer;

    // If the control is a button, it requires a nested span for centering to work on some browsers (Safari).
    // This is because <button> tags don't take different values for display.
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.has-icon .wowInput,
  &.has-control .wowInput {
    padding-right: 35px;
  }

  .wowLabel,
  .wowPlaceholder {
    position: absolute;
    left: $input-container-padding;
    font-size: $fontSize-base;
    pointer-events: none;
    transform: translate3d(0, $input-label-default-offset, 0)
      scale($input-label-default-scale);
    transform-origin: left top;
    transition: transform $swift-ease-out-timing-function
      $input-label-transition-speed;
    margin: 0;
    color: $color-grayMed;
  }

  .wowLabel {
    display: block;
    width: 100%;
    top: 22px;
    z-index: 1;
  }

  .wowPlaceholder {
    top: 0;
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d(
      0,
      $input-placeholder-offset + $input-placeholder-float-offset * 0.75,
      0
    );
    padding-right: 2px;
  }
  &.is-focused .wowPlaceholder {
    opacity: 1;
    transform: translate3d(0, $input-placeholder-offset, 0);
  }
  &.has-value .wowPlaceholder {
    // Placeholder should immediately disappear when the user starts typing
    transition: none;
    opacity: 0;
  }
  &.is-focused,
  &.has-value {
    .wowLabel {
      color: $color-grayDark;
      font-style: normal;
      transform: translate3d(0, $input-label-float-offset, 0)
        scale($input-label-float-scale);
    }
  }
  &.is-invalid .wowInput {
    border: 1px solid $color-red;
  }
  &.is-invalid .wowInput-errors {
    display: inline-block;
  }
}
// Styles to remove per-browser input oddities
// -------------------------------------------
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::-ms-clear {
  display: none;
}
input[type='submit'],
input[type='button'] {
  -webkit-appearance: none;
  border-radius: 0;
}
input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
input:-moz-ui-invalid {
  box-shadow: none;
}
