@import '_colors';

.ghost-animation {
  overflow: hidden;
  background: $color-softGrey;
  &:before {
    animation: 2s scroll infinite linear;
    background: linear-gradient(
      to right,
      $color-softGrey 0%,
      $color-ghosttile--alternative 50%,
      $color-softGrey 100%
    );
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    content: ' ';
    height: 100%;
    position: relative;
  }
  @keyframes scroll {
    0% {
      transform: translate(-200%, 0px);
    }
    100% {
      transform: translate(200%, 0px);
    }
  }
}
