[class^='u-flex'],
[class*=' u-flex'] {
  display: flex;
}

.u-flexColumn {
  flex-direction: column;
}

.u-flexRow {
  flex-direction: row;
}

.u-flexWrap {
  flex-wrap: wrap;
}

.u-flexSpacer,
.u-flexGrow {
  flex-grow: 1;
}

.u-flexCenter {
  justify-content: center;
  align-items: center;
}

.u-flexSpaceBetween {
  justify-content: space-between;
}

.u-flexSpaceEvenly {
  justify-content: space-evenly;
}

.u-flexStart {
  justify-content: flex-start;
}

.u-flexEnd {
  justify-content: flex-end;
}

.u-flexCenterMain {
  justify-content: center;
}

.u-flexCenterCross {
  align-items: center;
}
