/*
This spacing system is built on four core concepts: generics, inset, stack and inline. Whether applying space within a container or between two components,
the included options make for easier and more consistent design decisions.

In CSS terms, think of /inset/ as padding and /stack/ & /inline/ as margin.
*/

/*
Generic spacings
*/
$space-0: 0;
$space-xxxs: 1px;
$space-xxs: 2px;
$space-xs: 4px;
$space-sm: 8px;
$space-smx: 10px;
$space-xmd: 12px;
$space-md: 16px;
$space-mdx: 20px;
$space-mdxx: 22px;
$space-lg: 24px;
$space-2lg: 40px;
$space-xl: 32px;
$space-2xl: 48px;
$space-3xl: 56px;
$space-4xl: 64px;
$space-5xl: 72px;
$space-6xl: 80px;
$space-7xl: 88px;
$space-8xl: 96px;
$space-9xl: 104px;
$space-10xl: 112px;
$space-11xl: 120px;
$space-12xl: 128px;
$space-2xxl: 52px;
$space-md-less-1: 15px;
$space-9xl-sm: 100px;

/*
An inset is the space within a block from which elements—typography, images, icons, and more—are separated from the edge.
(Insetting the element - padding values)
*/
$space-inset-xs: 4px 4px 4px 4px;
$space-inset-sm: 8px 8px 8px 8px;
$space-inset-md: 16px 16px 16px 16px;
$space-inset-lg: 24px 24px 24px 24px;
$space-inset-xl: 32px 32px 32px 32px;
$space-inset-xxl: 64px 64px 64px 64px;

/*
Use stack tokens to separate components arranged vertically. The last instance of a component should omit this space.
(Stacking the element vertically - margin values)
*/
$space-stack-xs: 0 0 4px 0;
$space-stack-sm: 0 0 8px 0;
$space-stack-md: 0 0 16px 0;
$space-stack-lg: 0 0 24px 0;
$space-stack-xl: 0 0 32px 0;
$space-stack-xxl: 0 0 64px 0;

/*
Use inline tokens to separate elements arranged horizontally and that may wrap on the right.
(Stacking the elements horizontally - margin values)
*/
$space-inline-xs: 0 4px 0 0;
$space-inline-sm: 0 8px 0 0;
$space-inline-md: 0 16px 0 0;
$space-inline-lg: 0 24px 0 0;
$space-inline-xl: 0 32px 0 0;
$space-inline-xxl: 0 64px 0 0;

$space-inset-squish-xs: 2px 4px;
$space-inset-squish-sm: 4px 8px;
$space-inset-squish-md: 8px 16px;
$space-inset-squish-lg: 16px 32px;
$space-inset-squish-xl: 32px 64px;

$space-inset-stretch-xs: 2px 4px;
$space-inset-stretch-sm: 4px 8px;
$space-inset-stretch-md: 8px 16px;
$space-inset-stretch-lg: 16px 32px;
$space-inset-stretch-xl: 32px 64px;
