@import 'sizings';
@import 'spacing';
@import 'zindex';
@import 'colors';
@import 'breakpoints';
@import 'functions';
@import 'typography';
@import 'images';
@import 'box-shadows';

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');

@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');
// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 100%,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;
@include _assert-ascending($container-max-widths, '$container-max-widths');
// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
// Container padding
$container-padding-x: $grid-gutter-width * 0.5 !default;
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;
$hundred-percent: 100%;

// My account new design related variables
$width-card-content: 784px;
$small-mobile-content-width: 343px;
$card-max-width: 1200px;
