@import '_colors';

/* Hide validation messages until the form has been submitted */
.ng-invalid shared-validation-messages {
  display: none;
}

.ng-invalid.ng-submitted shared-validation-messages,
shared-validation-messages.show {
  display: inline-block;
}

.ng-submitted shared-textbox.ng-invalid,
.ng-submitted shared-checkbox.ng-invalid {
  shared-help-text {
    display: none;
  }
}

.ng-invalid.ng-submitted shared-textbox.ng-invalid input,
.ng-invalid.ng-submitted shared-typeahead.ng-invalid input,
.ng-invalid.ng-submitted shared-dropdown.ng-invalid select {
  border-color: $color-red;
}

.ng-invalid.ng-submitted
  shared-checkbox.ng-invalid
  .checkbox-container
  .checkbox-box {
  background-color: $color-red--lightest;
  border-color: $color-red;
}
