﻿@mixin horizontal-vertical-align() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-align() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-align() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
