@import 'typography';
@import 'colors';

.headerSearch {
  // PDX-620 - highlight text styles
  .headerSearch-autocompleteItem {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .highlight-text {
          color: $color-white;
        }
      }
    }
    .highlight-text {
      color: $color-midGreen;
      font-family: $fontFamily-headline;
    }

    &.is-itemSelected {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .highlight-text {
            color: $color-white;
          }
        }
      }
      .highlight-text {
        color: $color-white;
      }
    }
  }
}
