@import 'breakpoints';

.visible {
  &-xs {
    &-inline {
      @media (max-width: $screen-xs-max) {
        display: inline;
      }
      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }
    @media (max-width: $screen-xs-max) {
      display: block;
    }
    @media (min-width: $screen-sm-min) {
      display: none !important;
    }
  }

  &-sm {
    @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
      display: block;
    }
    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
}

.hidden {
  &-xs {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    @media (min-width: $screen-sm-min) {
      display: block;
    }
  }

  &-sm {
    @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
      display: none;
    }
    @media (min-width: $screen-md-min) {
      display: block;
    }
  }
}

[hidden] {
  display: none !important;
}
