// TYPOGRAPHY UTLITY CLASSES

.body-regular {
  font-family: $fontFamily-body;
  font-weight: $fontWeight-regular;
}

.body-medium {
  font-family: $fontFamily-body;
  font-weight: $fontWeight-medium;
}

.body-bold {
  font-family: $fontFamily-body;
  font-weight: $fontWeight-bold;
}

.body-emphasis {
  font-family: $fontFamily-body;
  font-style: italic;
}

.headline-regular {
  font-family: $fontFamily-headline;
  font-weight: $fontWeight-regular;
}

.headline-medium {
  font-family: $fontFamily-headline;
  font-weight: $fontWeight-medium;
}

.headline-bold {
  font-family: $fontFamily-headline;
  font-weight: $fontWeight-bold;
}
