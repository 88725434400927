@import '_variables';

.alert {
  font-size: $fontSize-base;
  color: $color-charcoal;
  font-weight: $fontWeight-semiBold;
  padding: 15px;
  margin: 15px 0;
  position: relative;
  border-style: solid;
  border-width: 1px;

  &-icon {
    position: absolute;
    top: $space-md;
    left: 12px;
  }

  &-content {
    padding-left: 30px;
  }

  &-serverErrorMessage {
    font-size: $fontSize-smallish--loginForm-forgot-password;
  }

  &--info {
    background-color: $color-yellowLight-messageBackground;
    border-color: $color-yellow-border;
  }

  &--error {
    background-color: $color-red-inlineMessageBackground;
    border-color: $color-red-inlineMessageBackground-border;

    .alert-icon {
      color: $color-red--error;
    }
  }

  &--success {
    background-color: $color-green-confirmation;
    border-color: $color-green-confirmation-border;
  }
}
