//== Colors
//
//## All of the colors used across the site. Define new colors at the bottom of this file.

// Grays
// -----------------------------------------------------
$color-grayDarkest: rgb(55, 55, 55); // #373737
$color-grayDarker: rgb(109, 109, 109);
$color-grayDark: rgb(117, 116, 118);
$color-grayMed: rgb(150, 150, 150);
$color-gray: rgb(204, 204, 204);
$color-grayLight: rgb(217, 217, 217);
$color-grayLighter: rgb(222, 223, 220);
$color-grayLightest: rgb(242, 243, 240);
$color-grayLighterest: rgb(248, 248, 248);

$color-mildGrey: rgb(212, 212, 212); // #D4D4D4
$color-softGrey: rgb(238, 238, 238); // #EEEEEE
$color-red: rgb(189, 22, 28); // #BD161C

$color-white: rgb(255, 255, 255); // #FFFFFF
$color-black: rgb(0, 0, 0); // #000000
$color-extended-zing-light: rgb(218, 241, 153); //DAF199
$color-grayTransparent: fade($color-grayMed, 70%);
$color-grayDarkestTransparent: fade($color-grayDarkest, 70%);

// Brand
// -----------------------------------------------------
$color--brandPrimary: rgb(37, 134, 30); // #25861e
$color--brandSecondary: rgb(62, 166, 54);
$color--brandZing: rgb(202, 235, 109); // #CAEB6D

// DAVE Colour Palette
// -----------------------------------------------------
// https://woolworthsdigital.atlassian.net/wiki/spaces/WU/pages/409207844/Colours

$color-darkGreen--darkest: rgb(9, 46, 26); // #092E1A
$color-darkGreen--darker: rgb(13, 62, 35); // #0D3E23
$color-darkGreen: rgb(18, 84, 48); // #125430
$color-darkGreen--light: rgb(65, 118, 89); // #417659
$color-darkGreen--lighter: rgb(113, 152, 131); // #719883
$color-darkGreen--lightest: rgb(160, 187, 172); // #A0BBAC
$color-darkGreen--bright: rgb(12, 72, 44); //  #0C482C

$color-midGreen--darkest: rgb(13, 81, 39); // #0D5127
$color-midGreen--darker: rgb(18, 108, 52); // #126C34
$color-midGreen: rgb(23, 136, 65); // #178841
$color-midGreen--light: rgb(69, 160, 103); // #45A067
$color-midGreen--lighter: rgb(116, 184, 141); // #74B88D
$color-midGreen--lightest: rgb(162, 207, 179); // #A2CFB3

$color-lightGreen: rgb(165, 200, 77); // #A5C84D
$color-lightGreen--light: rgb(198, 219, 145); // #C6DB91
$color-lightGreen--lighter: rgb(219, 233, 184); // #DBE9B8
$color-lightGreen--lightest: rgb(237, 244, 219); // #EDF4DB

$color-charcoal--darkest: rgb(23, 28, 31); // #171C1F
$color-charcoal--darker: rgb(40, 49, 54); // #283136
$color-charcoal: rgba(58, 71, 78); // #3A474E
$color-charcoal--light: rgb(97, 108, 113); // #616C71
$color-charcoal--lighter: rgb(137, 145, 149); // #899195
$color-charcoal--lightest: rgb(195, 199, 201); // #C3C7C9

$color-softGrey--darkest: rgb(209, 209, 209); // #D1D1D1
$color-softGrey--darker: rgb(224, 224, 224); // #E0E0E0
$color-softGrey--light: rgb(241, 241, 241); // #F1F1F1
$color-softGrey--lighter: rgb(245, 245, 245); // #F5F5F5

$color-yellow--darkest: rgb(229, 195, 0); // #E5C300
$color-yellow--darker: rgb(247, 211, 0); // #F7D300
$color-yellow: rgb(255, 218, 0); // #FFDA00
$color-yellow--light: rgb(255, 233, 102); // #FFE966
$color-yellow--lighter: rgb(255, 243, 178); // #FFF3B2
$color-yellow--lightest: rgb(255, 251, 229); // #FFFBE5

$color-orange--darkest: rgb(172, 89, 0); // #AC5900
$color-orange--darker: rgb(213, 113, 27); // #D5711B
$color-orange--dark: rgb(232, 123, 30); // #E87B1E
$color-orange: rgb(244, 121, 32); // #F47920
$color-orange--light: rgb(252, 217, 188); // #FCD9BC

$color-red--darkest: rgb(189, 22, 28); // #BD161C
$color-red--dark: rgb(208, 2, 27); // #D0021B
$color-red--darker: rgb(212, 25, 32); // #D41920
$color-red--light: rgb(244, 119, 124); // #F4777C
$color-red--lighter: rgb(249, 186, 189); // #F9BABD
$color-red--lightest: rgb(253, 232, 233); // #FDE8E9

$color-pink: rgb(230, 0, 126); // #E6007E

$color-blue: rgb(0, 116, 188); // #0074BC
$color-blue--light: rgb(102, 172, 215); // #66ACD7
$color-blue--lighter: rgb(237, 245, 250); // #EDF5FA

// Application
// -----------------------------------------------------
$color-gray--background: $color-softGrey;
$color-lightPink: rgb(252, 232, 233);
$color-red--specials: rgb(237, 28, 36); // #ED1C24
$color-orange--edr: rgb(255, 130, 15);
$color-purple: rgb(153, 51, 153);
$color-yellow-border: rgb(249, 201, 45);
$color-yellowLight-messageBackground: rgb(254, 249, 234);
$color-red--error: $color-red;
$color-gray--dropShadow: rgba($color-black, 0.15);
$color-green-confirmation: rgb(228, 238, 228);
$color-green-confirmation-border: $color--brandPrimary;
$color-greenLight-messageBackground: $color-lightGreen--lightest;
$color-green-border: $color-lightGreen;
$color-limed-spruce: rgb(57, 70, 78); // #39464E
$color-limited-gray: rgb(41, 50, 55); // #293237
$color-limited-orange: rgb(177, 70, 0); // #B14600

// General
// -----------------------------------------------------
$color-black--mask: rgba($color-black, 0.5);

// Foodhub
// -----------------------------------------------------
$color-charcoal--videoButton: rgba($color-charcoal--darkest, 0.25);

// Navigation
// -----------------------------------------------------
$color-grayLight--activeLinkBackground: $color-grayLighter;

// Login
// -----------------------------------------------------
$color-grayLightest--loginForm-separator: $color-grayLighter;

// Checkout wizard
//--------------------------------------------------------
$color-blue--checkoutwizard-packagingFeeFindout: rgb(0, 116, 188);
// Inline Messaging
// --------------------------------------------------------
$color-red-inlineMessageBackground: $color-lightPink;
$color-red-inlineMessageBackground-border: $color-red;
// Footer
// --------------------------------------------------------
$color-grayDarker--footerBackground: rgb(192, 192, 192);
$color-grayDark--footerBackground: rgb(211, 211, 211);
$color-grayLightest--footerLine: rgb(233, 233, 233);
$color-grayLightest--footerLinkBorder: rgb(249, 249, 249);
// Rewards
// --------------------------------------------------------
$color-orange-rewards--darker: rgb(176, 65, 1); // #B04101
$color-orange-rewards--dark: rgb(212, 69, 0); // #D44500
$color-orange-rewards--light: rgb(248, 160, 124); // #F8A07C
$color-orange-rewards: rgb(253, 100, 0); // #FD6400
$color-orange-rewards-bg: rgb(253, 239, 228); // #FDEFE4
$color--brandRewards: rgb(244, 121, 32); // #F47920
$color-rewards-plus-content: rgb(43, 54, 61); // #2B363D
$color-grey-rewards: rgb(56, 69, 79); // #38454F
// Visual Shopping Version 2 Drop Shadows
// --------------------------------------------------------
$color-grayDark--dropShadow: rgba(50, 50, 50, 0.5);
// Order Confirmation Print
// --------------------------------------------------------
$color-gray--confirmationprint: rgb(244, 244, 244);
// Tooltip Drop Shadows
// --------------------------------------------------------
$color-toolTip--dropShadow: rgba(0, 0, 0, 0.3);
// Cart icon color
// --------------------------------------------------------
$color-orange--rewardCartIcon: rgb(240, 120, 54);
// Full-page loading spinner mask drop shadows
// --------------------------------------------------------
$color-loadingSpinner--dropShadow: rgba(0, 0, 0, 0.3);
// Ghost Tiles
// --------------------------------------------------------
$color-ghosttile--alternative: rgb(230, 230, 230);
$color-ghosttile--background: #e0e1e2;
$color-ghosttile--highlight: #f7f8f8;
// Product Tiles Redesign
// --------------------------------------------------------
$color-producttile-border: rgb(247, 247, 247); // #F7F7F7
$color-lightGray-boxShadow: rgba(58, 71, 78, 0.15);
// DigitalPay
// --------------------------------------------------------
$color-digitalPay-border: $color-softGrey--darkest;
$color-digitalPay-paypal: rgb(31, 91, 155);
$color-digitalPay-paypal--light: rgb(7, 114, 181);
// -------------------------------------------------------------
// Product details image zoomer
// -------------------------------------------------------------
$color-zoomImageContainer-border: rgb(136, 136, 136);
// Form Validation
// -------------------------------------------------------------
$color-FormValidation-Error-Red: rgb(189, 0, 7); // #BD0007
// Charcoal grey light
// -------------------------------------------------------------
$color-charcoalGrey: rgb(58, 71, 78); // #3A474E
$color-charcoalGrey--light: rgb(98, 117, 132); // #627584
// Marketplace Brand Color
$color-marketplace-brand: #d44500;
$color-marketplace-brand-light: #ffa462;
// offer boosted background
$color-offer-boosted-background: #cfe2d8;

// Product Tag Template Themes
// -------------------------------------------------------------
$color-productTagTheme-origin: #a66707;
$color-productTagTheme-allergen: #8c1397;
$color-productTagTheme-environmental: #067e99;
$color-productTagTheme-marketing: #a5c84d;
$color-productTagTheme-new: #e6007e;
$color-productTagTheme-rtl: #d44500;
$color-productTagTheme-value: #d41920;
$color-productTagTheme-memberPricing: #d44500;

// FS Header Colors
// -------------------------------------------------------------
$color-header-mask: rgba(23, 28, 31, 0.6);
$color-spinner-grey: rgb(128, 133, 142);
$color-button-tertiary: #f5f6f6;
$color-button-tertiary-highlighted: #e1e3e4;
$color-button-tertiary-pressed: #babfc1;
$color-button-disabled: #ced1d3;
$color-button-pressed: #ebeded;
$color-action-focus: #3d6d56;
$color-intro-border: #e2e2e2;
$color-brand-default: #b14600;
$color-brand-focus: #7f3200;

$color-interface-border-medium: #9da3a7;
